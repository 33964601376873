import clsx from 'clsx';
import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'next-i18next';

import { Button } from '../Button';

import heaxagon from '../../../assets/image/hexagonMultiple.png';
import Image from 'next/image';

gsap.registerPlugin(ScrollTrigger);

export const CTA = ({
  dark = false,
  fullHeight = true,
}: {
  dark?: boolean;
  fullHeight?: boolean;
}) => {
  const { t } = useTranslation('common');

  return (
    <section
      id="cta"
      className={clsx(
        'relative flex w-full items-center justify-center overflow-hidden px-8 py-40 text-center text-white',
        {
          'bg-gradient': dark,
          'bg-white': !dark,
          'lg:min-h-screen': fullHeight,
          'lg:min-h-[70vh]': !fullHeight,
        },
      )}>
      <Image
        className="absolute bottom-0 right-[-3vw] h-full -skew-y-6 object-contain opacity-5"
        src={heaxagon}
        alt="hexagon"
        blurDataURL={heaxagon}
        width={960}
        height={540}
      />

      <div
        id="cta-content"
        className={clsx('mx-auto lg:max-w-[1050px]', { 'text-white': dark, 'text-black': !dark })}>
        <div
          id="cta-subtitle"
          className={clsx('mb-4 font-bold uppercase', {
            'text-white': dark,
            'text-black': !dark,
          })}>
          {t('ctaSection.gotAProject')}
        </div>

        <h2
          className={clsx('text-heading-about font-bold lg:-mt-2', {
            'text-white': dark,
            'text-black': !dark,
          })}
          dangerouslySetInnerHTML={{ __html: t('ctaSection.letsDiscussYourIdea') }}
        />

        <div className="flex flex-col items-center justify-center">
          <p
            className={clsx('mt-10', {
              'text-white': dark,
              'text-black': !dark,
            })}>
            {t('ctaSection.doYouHaveQuestions')}
          </p>
          <p
            className={clsx('mt-2 max-w-[80vw] lg:max-w-[600px]', {
              'text-white': dark,
              'text-black': !dark,
            })}>
            {t('ctaSection.weWillTakeTheTime')}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <Button
            className="mt-14 justify-center"
            href={t('common:contactProjectSlug')}
            type="primary">
            {t('ctaSection.ctaButton')}
          </Button>
        </div>
      </div>
    </section>
  );
};
